import { configureStore } from "@reduxjs/toolkit";
import GestionImage from "./slice/gestionImageSlice";
import InfoClient from "./slice/InfoClientSlice";

const store = configureStore({
  reducer: {
    gestionImage: GestionImage,
    InfoCommand: InfoClient,
  },
});

export default store;
