import {
  Box,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Button,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { api_base } from "../../env";
import { useNavigate, useParams } from "react-router";
import { useMonContexte } from "../../store/ContextComposant";
import BackLoading, {
  BackLoadingSimple,
} from "../../composant/loading/backLoading";
import { useDispatch, useSelector } from "react-redux";
import { VidePanier } from "../../store/slice/gestionImageSlice";
import { ImportButton } from "../../composant/UploadFiles";
import MessageSucces from "../../composant/message/succesMessage";
import { StorageDataClient } from "../../store/slice/InfoClientSlice";
const magasin = [{ label: "Casablanca" }, { label: "Rabat" }];
const ville = [{ label: "Casablanca" }, { label: "Rabat" }];
export default function Info_client() {
  const formRef = useRef();
  const Navigate = useNavigate();
  const [magasinValue, setMagasin] = useState("");
  const { TableauImage, setTableauImage } = useMonContexte();
  const [Loading, setLoading] = useState(false);
  const [NumberUpload, setNumberUpload] = useState(0);
  const [NumberFileUpload, setNumberfileUpload] = useState(0);
  const [messageError, setMessageerror] = useState();
  const [succes, setSucces] = useState(false);
  const [LoadingSimple, setLoadingSimple] = useState(false);
  const clientInfo = useSelector((state) => state.InfoCommand.clientCommand);
  const { polaroid, instax, Evenements } = useSelector(
    (state) => state.gestionImage.allImages
  );
  const { type } = useParams();
  console.log(type);
  const dispatch = useDispatch();
  async function submitCommand(e) {
    setMessageerror();
    e.preventDefault();
    const { nom, telephone, magasin, adresse, ville, evenments } = e.target;
    {/*const trimmedPhone = telephone.value.trim();
    if (trimmedPhone.length < 10) {
      setMessageerror("numro telephone invalide");
      return;
    }*/}
    setLoadingSimple(true);
    const data = {
      nom: nom.value,
      telephone: telephone.value,
      magasin: magasin?.value || null,
      adresse: adresse?.value || null,
      ville: ville?.value || null,
      evenments: evenments?.value || null,
    };
    await axios
      .post(`${api_base}nodeCamera/command/add`, data)
      //.post(`http://192.168.1.9:5000/nodeCamera/command/add`, data)
      .then((response) => {
        sendData(response.data.id);
        dispatch(StorageDataClient(data));
      })
      .catch((error) => {
        alert("error");
        console.log(error);
      });
    setLoadingSimple(false);
  }
  async function sendData(id) {
    setNumberUpload(polaroid.length + instax.length + Evenements.length);
    const Alldata = [...polaroid, ...instax, ...Evenements];
    setLoading(true);
    const dataNotUpload = [];
    for (let index = 0; index < Alldata.length; index++) {
      const data = { ...Alldata[index], idcommand: id };
      try {
        await axios.post(`${api_base}nodeCamera/addManyImage`, data);
        setNumberfileUpload(index + 1);
      } catch (error) {
        dataNotUpload.push(Alldata[index]);
        continue;
      }
    }
    dispatch(VidePanier());
    setLoading(false);
    if (!dataNotUpload.length) {
      setSucces(true);
    } else {
      setTableauImage(dataNotUpload);
      Navigate(`/upload/${id}`);
    }
  }
  console.log(LoadingSimple);
  return (
    <Box>
      <Box component="form" onSubmit={submitCommand} ref={formRef}>
        <Typography variant="h3" fontSize={40} textAlign="center">
          info client
        </Typography>
        <Grid
          container
          rowSpacing={4}
          marginTop="4%"
          width="40%"
          marginLeft="30%"
          sx={{
            marginTop: "4%",
            width: "40%",
            marginLeft: "30%",
            "@media (max-width: 700px)": {
              width: "100%",
              marginLeft: "0%",
              padding: "3%",
            },
          }}
        >
          <Grid item xs={4}>
            <Typography variant="h6">Telephone :</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              placeholder="telephone"
              name="telephone"
              label="telephone"
              fullWidth
              error={messageError}
              helperText={messageError && messageError}
              required
              defaultValue={clientInfo?.telephone}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Nom :</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              placeholder="Nom"
              label="Nom"
              name="nom"
              fullWidth
              required
              defaultValue={clientInfo?.nom}
            />
          </Grid>

          {type == "inStore" ? (
            <>
              <Grid item xs={4}>
                <Typography variant="h6">Magasin :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  disablePortal
                  id="magasin"
                  isOptionEqualToValue={(option, value) => option.label === clientInfo?.magasin}
                  options={magasin}
                  defaultValue={clientInfo?.magasin}
                  onChange={(e, v) => {
                    setMagasin(v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="magasin"
                      required
                      name="magasin"
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  placeholder="Adresse"
                  label="Adresse"
                  name="adresse"
                  defaultValue={clientInfo?.adresse}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="ville"
                  isOptionEqualToValue={(option, value) =>
                    option.label === clientInfo?.ville
                  }
                  defaultValue={clientInfo?.ville}
                  options={ville}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="ville"
                      name="ville"
                      required
                    />
                  )}
                />
              </Grid>
            </>
          )}
          {Evenements.length > 0 && (
            <Grid item xs={12}>
              <TextField
                placeholder="Evenements"
                label="Evenements"
                name="evenments"
                fullWidth
                required
                defaultValue={clientInfo?.evenments}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ImportButton variant="contained" type="submit" fullWidth>
              commande
            </ImportButton>
          </Grid>
        </Grid>
      </Box>
      <MessageSucces
        open={succes}
        onClose={() => {
          setSucces(false);
          Navigate("/");
        }}
      />
      <BackLoading
        open={Loading}
        numberfile={NumberUpload}
        fileupload={NumberFileUpload}
      />
      <BackLoadingSimple open={LoadingSimple} />
    </Box>
  );
}
