import { Grid, Box, Typography, Button, Badge } from "@mui/material";
import { useState } from "react";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import { useDispatch } from "react-redux";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useNavigate } from "react-router";
import { addImageAndSave } from "../../store/slice/gestionImageSlice";
import ImageCard from "../../composant/ImageGallrey";
import ImageTirage from "../../image/imageTiragePolaroid.png";
import PhotoImportCard from "../../composant/UploadFiles";
import { ImportButton } from "../../composant/UploadFiles";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { resizeImage } from "../../composant/resizeImage";
export default function ImageUploadPolariod() {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /*
  const [base64Image, setBase64Image] = useState(null);
  useEffect(() => {
    convertBlobToBase64();
  }, [uploadedImage]);

  const convertBlobToBase64 = async (uploadedImage) => {
    if (uploadedImage !==  null) {
      try {
        const response = await fetch(uploadedImage);
        const blobData = await response.blob();
        console.log(blobData)
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result;
          setBase64Image(base64String);
        };

        reader.readAsDataURL(blobData);
      } catch (error) {
        console.error("Error converting blob to base64:", error);
      }
    }
  };
console.log(base64Image)*/
  function convertFileToBase64(
    file,
    callback,
    maxWidth = 800,
    maxHeight = 600,
    quality = 0.7
  ) {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            callback(reader.result);
          };
          reader.onerror = (error) => {
            console.error("Error converting file to Base64:", error);
          };
        },
        "image/jpeg",
        quality
      ); // Adjust the format ('image/jpeg') and quality (0.7) as needed
    };
    img.onerror = (error) => {
      console.error("Error loading image:", error);
    };
  }
  async function AddImage(e) {
    setLoading(true); // Assume setLoading is correctly managing the loading state
    const acceptedFiles = e.target.files;

    const imageUploadPromises = Array.from(acceptedFiles).map(
      (file) =>
        new Promise((resolve, reject) => {
          convertFileToBase64(file, (base64String) => {
            // Make sure convertFileToBase64 is defined and works as expected
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
              // Assuming you want to maintain a square aspect ratio
              let canvasSize = 886; // Use this for both width and height for a square canvas

              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = canvasSize; // Square canvas width
              canvas.height = canvasSize; // Square canvas height

              let cropSize, startX, startY;

              if (img.width > img.height) {
                // Image is wider than it is tall, so crop to the height
                cropSize = img.height; // Use the height as the size for the square crop
                startX = (img.width - img.height) / 2;
                startY = 0;
              } else {
                // Image is taller than it is wide, or it's already square
                cropSize = img.width; // Use the width as the size for the square crop
                startX = 0;
                startY = (img.height - img.width) / 2;
              }

              // Draw the cropped square image on the canvas
              ctx.drawImage(
                img,
                startX,
                startY,
                cropSize, // source square crop width
                cropSize, // source square crop height
                0,
                0,
                canvas.width, // destination canvas width
                canvas.height // destination canvas height
              );
              const resizedImageData = canvas.toDataURL("image/jpeg", 0.9);
              resolve({
                originalBase64: base64String,
                src: resizedImageData,
                marginTop: 72,
                marginLeft: 92,
                height: 1205,
                width: 1795,
                type: "polaroid",
              });
            };

            img.onerror = () => reject(new Error("Failed to load image"));
          });
        })
    );

    try {
      const images = await Promise.all(imageUploadPromises);
      setImage((prevImages) => [...prevImages, ...images]);
    } catch (error) {
      console.error("Error processing images:", error);
    } finally {
      setLoading(false); // Make sure to turn off loading state
    }
  }

  const addpanier = async () => {
    dispatch(addImageAndSave({ images: image, type: "polaroid" }));
    navigate("/panier");
  };
  const deleteImage = async (index) => {
    setImage(() => image.filter((item, i) => i !== index));
  };

  return (
    <Box>
      <Grid
        container
        spacing={4}
        sx={{
          marginTop: "8%",
          marginBottom: {
            xs: "15%", // Applies to xs and up, until overridden
            md: "5%", // Overrides the above value from md breakpoint and up
          },
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontWeight: "regular" }}
            >
              Tirage photo
            </Typography>
            <Box
              component="span"
              sx={{
                display: "inline-block",
                height: "24px",
                width: "1px",
              }}
            />
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
              {" "}
              - Tirage Polaroid
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} textAlign="center">
            {image.length == 0 && (
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={ImageTirage}
                  alt="ImageTirage"
                  width="80%"
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={image.length == 0 ? 6 : 12}
              textAlign="center"
            >
              <PhotoImportCard
                AddImage={AddImage}
                affichage={image.length == 0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Grid container spacing={4} textAlign="center">
            {image?.map((image, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                key={index}
                sx={{ width: "100%" }}
                textAlign="center"
              >
                <ImageCard
                  src={image.src}
                  onDelete={() => deleteImage(index)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>{" "}
      {image.length != 0 && (
        <>
          <footer
            style={{
              display: "flex",
              justifyContent: "flex-end",

              borderTop: "1px solid #ccc",
              position: "fixed",
              left: "0",
              bottom: "0",
              width: "100%",
              background: "white",
            }}
          >
            <ImportButton
              variant="contained"
              startIcon={
                <Badge
                  badgeContent={image.length}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#e63e57",
                      backgroundColor: "white",
                    },
                  }}
                >
                  <AddShoppingCartIcon />
                </Badge>
              }
              onClick={addpanier}
            >
              Ajouter au Panier
            </ImportButton>
          </footer>
        </>
      )}
      <BackLoadingSimple open={loading} />
    </Box>
  );
}
