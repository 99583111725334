import {
  Grid,
  CardMedia,
  Dialog,
  Card,
  Button,
  DialogActions,
  DialogTitle,
  Typography,
  DialogContent,
} from "@mui/material";

function ImageGallreyOriginal({ open, images, handleClose }) {
  const imageTypes = [...new Set(images.map((image) => image.type))];

  // Affichage des images par type
  const renderImagesByType = (type) => {
    const filteredImages = images.filter((image) => image.type === type);

    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Images {type.charAt(0).toUpperCase() + type.slice(1)}{" "}
            {/* Capitalize the first letter */}
          </Typography>
        </Grid>
        {filteredImages.map((image, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {" "}
            {/* Adjust grid sizing for responsiveness */}
            <Card>
              <CardMedia
                component="img"
                image={image.imageUrl}
                alt={`${type} Image ${index}`}
              />
            </Card>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      fullWidth={true}
      maxWidth="md" // Vous pouvez ajuster la taille selon vos besoins
    >
      <DialogTitle>
        <Typography variant="h3" textAlign="center">
          image originale
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ padding: 20 }}>
          {imageTypes.map((type, index) => renderImagesByType(type))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImageGallreyOriginal;
