// MonContexte.js
import React, { createContext, useContext, useState } from 'react';

const MonContexte = createContext();

export const MonContexteProvider = ({ children }) => {
  const [TableauImage, setTableauImage] = useState([]);
  const [AddImage , setAddImage] = useState([])

  return (
    <MonContexte.Provider value={{ TableauImage, setTableauImage , AddImage , setAddImage }}>
      {children}
    </MonContexte.Provider>
  );
};

export const useMonContexte = () => {
  return useContext(MonContexte);
};
