import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import ImagePage from "./page/Admin/ImagePage";
import Home from "./page/Client/home";
import ImageUploadInstax from "./page/Client/UploadImageInstax";
import Info_client from "./page/Client/command";
import Panier from "./page/Client/Panier";
import ImageApresCommand from "./page/Client/imageNotupload";
import ImageUploadPolariod from "./page/Client/UploadImagePolaroid";
import DrawerAppBar from "./composant/navbar/Navbar";
import { CssBaseline } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initializeState } from "./store/slice/gestionImageSlice";
import { GetInfoClient } from "./store/slice/InfoClientSlice";

function App() {
  const dispatch = useDispatch()
  useEffect(()=>{
   dispatch(initializeState())
   dispatch(GetInfoClient())
  },[dispatch])
  return (
    <div className="App">
      <BrowserRouter>
        <DrawerAppBar/>
        <div
          style={{ background: "#f7f7f7", minHeight:"100vh" }}
        >
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/polaroid" element={<ImageUploadPolariod />} />
            <Route path="/:type" element={<ImageUploadInstax />} />
            <Route path="/panier" element={<Panier />} />
            <Route path="/commande/:type" element={<Info_client />} />
            <Route path="/upload/:id" element={<ImageApresCommand />} />
            <Route path="/tableau" element={<ImagePage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
