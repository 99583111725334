
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

export default  function BackLoading({ open , numberfile , fileupload}) {
  return (
    <Backdrop
      sx={{   display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      flexDirection:"column",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,  }}
      open={open}
    >
      <CircularProgress  />
      <Typography variant='h4' color="white">imgae upload {numberfile}/{fileupload}</Typography>
    </Backdrop>
  );
}

export  function BackLoadingSimple({ open }) {
  return (
    <Backdrop
      sx={{   display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      flexDirection:"column",
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,  }}
      open={open}
    >
      <CircularProgress  />
      
    </Backdrop>
  );
}


/* 

color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 



sx={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999, 
}}>*/






