import React, { useRef } from "react";
import { Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/system";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

// Si vous utilisez le système de thème, vous pouvez le personnaliser pour appliquer des styles globaux
export const ImportButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#e63e57",
  color: "white",
  "&:hover": {
    backgroundColor: "#E9535D",
  },
  borderRadius: "10px",
  height:'50px',
  padding: theme.spacing(1),
}));

export const ImportButtonSimple = styled(Button)(({ theme }) => ({
  backgroundColor: "#ea8394",
  color: "white",
  "&:hover": {
    backgroundColor: "#E9535D",
  },
  borderRadius: "20px",
  padding: theme.spacing(1),
}));

const TextStyle = {
  marginTop: "5%",
  fontWeight: "bold",
  fontSize: "2rem",
};

const PhotoImportCard = ({ AddImage, affichage }) => {
  const fileInputRef = useRef(null);
  console.log(affichage);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#f7f7f7",
        textAlign: "center",
        padding: "20px",
        gap: "20px",
      }}
    >
      {/* Bouton d'importation affiché en premier */}
      <ImportButton
        variant="contained"
        onClick={handleButtonClick}
        startIcon={<AddPhotoAlternateIcon />}
        sx={{ order: { xs: 1, sm: 2 } }} // Utilisez l'objet sx pour appliquer l'ordre conditionnel
      >
        Importer des photos
      </ImportButton>

      {/* Conditionnellement afficher les Typography si affichage est true */}
      {affichage && (
        <>
          <Typography
            variant="h4"
            sx={{ ...TextStyle, order: { xs: 2, sm: 1 } }}
            gutterBottom
          >
            Choisissez vos photos préférées! 🔥
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "1rem", order: { xs: 3, sm: 1 } }}
          >
            Nous acceptons les fichiers JPEG, JPG, PNG et HEIC (seulement sur
            iOS et MacOS), jusqu'à 100 Mo.
          </Typography>
        </>
      )}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }} // Masquer l'input
        accept="image/*"
        multiple
        onChange={AddImage}
      />
    </Box>
  );
};

export default PhotoImportCard;
