import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useEffect, useState } from "react";
import { api_base } from "../../env";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import { Button, IconButton, Paper, TablePagination } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImageGallreyOriginal from "../../composant/admin/ImageGallreyOriginal";
import ImagegallreyTraite from "../../composant/admin/ImagegallreyTraite";
import { Container } from "@mui/material";
function ImagePage() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleImage, setVisibleImage] = useState(false);
  const [VisibleImageTraite, setVisibleImageTraite] = useState(false);
  const [Image, setImage] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  useEffect(() => {
    getImage();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  async function getImage() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${api_base}nodeCamera/command/getcommad?page=${page}&limit=${rowsPerPage}`
      );
      setImages(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function getDate(dt) {
    const date = new Date(dt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Note: Months are zero-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${year}-${month}-${day} | ${hours}:${minutes}`;
  }

  async function downloadtaiteZib(id, nom, dt) {
    setLoading(true);
    try {
      const res = await axios.get(
        `${api_base}nodeCamera/image/getImagezib/${id}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nom}_${getDate(dt)}_Traite.zip`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error downloading image:", error);
    }
  }

  async function downloadOriginalZib(id, nom, dt) {
    setLoading(true);
    try {
      const res = await axios.get(
        `${api_base}nodeCamera/image/getImageOzib/${id}`,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nom}_${getDate(dt)}_original.zip`);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error downloading image:", error);
    }
  }

  async function getImageParId(id) {
    setLoading(true);
    try {
      const res = await axios.get(
        `${api_base}nodeCamera/image/ImagesParId/${id}`
      );
      console.log(res.data);
      setImage(res.data);
      setVisibleImage(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function getImageParIdtraite(id) {
    setLoading(true);
    try {
      const res = await axios.get(
        `${api_base}nodeCamera/image/ImagesParId/${id}`
      );
      console.log(res.data);
      setImage(res.data);
      setVisibleImageTraite(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function downloadImage(url, filename, id) {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(response.data);
      link.href = objectURL;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
      const data = {
        id: id,
        typeImage: filename,
      };
      const res = axios.post(
        `${api_base}nodeCamera/image/newDowloandImage`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">nom</TableCell>
                <TableCell align="center">telephone</TableCell>
                <TableCell align="center">magasin</TableCell>
                <TableCell align="center">adresse</TableCell>
                <TableCell align="center">ville</TableCell>
                <TableCell align="center">date</TableCell>
                <TableCell align="center">etat</TableCell>
                <TableCell align="center">evenement</TableCell>
                <TableCell align="center">original</TableCell>
                <TableCell align="center">Traite</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {images?.data?.map((i) => (
                <TableRow key={i.id}>
                  <TableCell align="center">
                    <h3>{i.nom} </h3>
                  </TableCell>
                  <TableCell align="center">
                    <h3>{i.telephone} </h3>
                  </TableCell>
                  <TableCell align="center">
                    <h3>{i?.magasin}</h3>
                  </TableCell>
                  <TableCell align="center">
                    <h3>{i?.adresse}</h3>
                  </TableCell>
                  <TableCell align="center">
                    <h3>{i?.ville}</h3>
                  </TableCell>
                  <TableCell align="center">
                    <h3>{getDate(i.createdAt)}</h3>
                  </TableCell>
                  <TableCell align="center">
                    <h3>{i?.etat}</h3>
                  </TableCell>
                  <TableCell align="center">
                    <h3>{i?.evenments}</h3>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        getImageParId(i.id);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      onClick={() => {
                        downloadOriginalZib(i.id, i.nom, i.createdAt);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      variant="contained"
                      onClick={() => {
                        getImageParIdtraite(i.id);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      onClick={() => {
                        downloadtaiteZib(i.id, i.nom, i.createdAt);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={images?.totalCount || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 25, 100]}
        />
      </Paper>
      <BackLoadingSimple open={loading} />
      <ImageGallreyOriginal
        open={visibleImage}
        images={Image}
        handleClose={() => {
          setVisibleImage(false);
        }}
      />
      <ImagegallreyTraite
        open={VisibleImageTraite}
        images={Image}
        handleClose={() => {
          setVisibleImageTraite(false);
        }}
      />
    </Container>
  );
}

export default ImagePage;
