import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useState } from "react";
import { Link } from "react-router-dom";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Badge, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import Logo from "../../image/okprintLogo.jpg";

const drawerWidth = 240;
const navItems = [
  { id: 1, name: "Home", Link: "/" },
  { id: 2, name: "instax", Link: "/instax" },
  { id: 3, name: "polaroid", Link: "/polaroid" },
  //{ id: 3, name: "Evenements", Link: "/Evenements" },

];
const StyleLogo = {
  marginRight: "auto",
  "@media (max-width: 600px)": {
    width: "50%",
  },
};
export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { polaroid, instax , Evenements } = useSelector(
    (state) => state.gestionImage.allImages
  );

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item) => (
          <Link to={item.Link} style={{ textDecoration: "none" }}>
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                sx={{
                  textAlign: "center",
                }}
              >
                <ListItemText primary={item.name} sx={{ color: "black" }} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          background: "white",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          {/* Replace `Logo` with your actual logo path */}
          <Link to="/" style={{ textDecoration: 'none' }}> {/* Apply styles to the link */}
      <Box
        component="img"
        src={Logo}
        alt="Logo"
        sx={{
          marginRight: 'auto',
          width: { xs: '100%', sm: '80%' }, // Adjusted width for responsiveness
          display: 'block', // Ensures that the image is displayed as a block element
          maxWidth: '100%', // Ensures that the image doesn't exceed its container's width
        }}
      />
    </Link>
          <Box sx={{ display: { xs: "none", sm: "block" }, flexGrow: 1 }}>
            {navItems.map((item) => (
              <Link
                to={item.Link}
                key={item.id}
                style={{ textDecoration: "none" }}
              >
                <Button sx={{ color: "black", margin: "0 10px" }}>
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" }, ml: 1 }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Box sx={{ justifyContent: "flex-end" }}>
            <MenuItem>
              <Link
                to="/panier"
                style={{ textDecoration: "none", color: "black" }}
              >
                <IconButton size="large" aria-label="show cart items">
                  <Badge
                    badgeContent={polaroid.length + instax.length + Evenements.length}
                    color="error"
                  >
                    <ShoppingBasketIcon />
                  </Badge>
                </IconButton>
              </Link>
            </MenuItem>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
