import { useMonContexte } from "../../store/ContextComposant";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Grid,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Container,
} from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import Badge from "@mui/material/Badge";
import CancelIcon from "@mui/icons-material/Cancel";
import polaroid from "../../image/polaroid.jpg";
import polaroidpanier from "../../image/polaroidPanier.jpg";
import instaxPanier from "../../image/instaxPanier.jpg";

import {
  DeleteImageInstax,
  DeleteImagePolaroid,
  VideInstax,
  VidePanier,
  VidePolaroid,
  VideTypeImage,
  VideTypeImageAndSave,
} from "../../store/slice/gestionImageSlice";
import { TroubleshootRounded } from "@mui/icons-material";
import ImageGallreyPanier from "../../composant/Imagegallery";

import Checkbox from "@mui/material/Checkbox";
import { ImportButton } from "../../composant/UploadFiles";
import ConfirmationDelete from "../../composant/message/ConfirmationDelete";
import ImageCard from "../../composant/ImageGallrey";

const Panier = () => {
  const { TableauImage, setTableauImage } = useMonContexte();

  const [ImageType, setImageType] = useState("");
  const ImagePopup = useSelector(
    (state) => state.gestionImage.allImages[ImageType]
  );
  const [AfficherImage, SetAfficherImage] = useState(false);
  const photos = useSelector((state) => state.gestionImage.allImages);
  const dispatch = useDispatch();
  const photoTypes = Object.keys(photos).filter(
    (key) => photos[key].length > 0
  );

  const [selectedOption, setSelectedOption] = useState("delivery");
  const [deletePopup, setDeletePopup] = useState(false);
  const [TypeDelete, setTypeDelete] = useState("");
  const handleSelect = (option) => {
    setSelectedOption(selectedOption === option ? "delivery" : option);
  };
  return (
    <Box>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          padding: 0,
          marginTop: "50px",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        {photoTypes.length > 0 ? (
          <>
            <Box sx={{ display: { xs: "none", md: "flex", width: "100%" } }}>
              <PanierPc
                photoTypes={photoTypes}
                photos={photos}
                handleSelect={handleSelect}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setImageType={setImageType}
                SetAfficherImage={SetAfficherImage}
                setDeletePopup={setDeletePopup}
                setTypeDelete={setTypeDelete}
              />
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <PanierTele
                photoTypes={photoTypes}
                photos={photos}
                handleSelect={handleSelect}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setImageType={setImageType}
                SetAfficherImage={SetAfficherImage}
                setDeletePopup={setDeletePopup}
                setTypeDelete={setTypeDelete}
              />
            </Box>
            <ImageGallreyPanier
              open={AfficherImage}
              images={ImagePopup}
              handleClose={() => SetAfficherImage(false)}
              type={ImageType}
            />
          </>
        ) : (
          <Paper>
            <Typography
              sx={{
                fontSize: {
                  xs: "24px",
                  md: "36px",
                  lg: "48px",
                  xl: "60px",
                },
                fontWeight: "bold",
                textAlign: "center",
              }}
              variant="p"
              padding="10px"
              color="gray"
            >
              Aucune image disponible. Veuillez en télécharger.
            </Typography>
          </Paper>
        )}
      </Container>
      <ConfirmationDelete
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        onConfirme={() => {
          dispatch(VideTypeImageAndSave(TypeDelete));
          setDeletePopup(false);
        }}
      />
    </Box>
  );
};

function PanierPc({
  photoTypes,
  photos,
  handleSelect,
  selectedOption,
  setSelectedOption,

  setImageType,
  SetAfficherImage,
  setTypeDelete,
  setDeletePopup,
}) {
  const dispatch = useDispatch();
  return (
    <Box width="100%" textAlign="center">
      <Paper sx={{ width: "100%", borderRadius: "25px" , marginTop:"150px" }}>
        <Typography variant="p" padding="10px" fontSize="30px" color="gray">
          Liste et prix de votre commande
        </Typography>
        <List>
          {photoTypes.map((photoType, index) => (
            <ListItem
              key={photoType}
              sx={{
                height: "150px",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "start", sm: "center" },
              }}
            >
              <Box
                sx={{
                  height: { xs: "50%", sm: "60%" },
                  marginBottom: { xs: 1, sm: 0 },
                  width: { xs: "100%", sm: "auto" },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={photoType == "polaroid" ? polaroidpanier : instaxPanier}
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    height: "auto",
                    width: "auto",
                  }}
                  alt={`Photo ${photoType}`}
                />
              </Box>
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: { xs: "1.5rem", sm: "2rem" },
                  },
                  marginX: { xs: 1, sm: 0 },
                }}
                primary={`Photo ${
                  photoType.charAt(0).toUpperCase() + photoType.slice(1)
                }`}
              />
              <ListItemSecondaryAction
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flexDirection: { xs: "column", sm: "row" },
                  marginTop: { xs: 1, sm: 0 },
                }}
              >
                <Badge badgeContent={photos[photoType].length} color="error">
                  <CollectionsIcon fontSize="large" />
                </Badge>
                <IconButton
                  onClick={() => {
                    setImageType(photoType);
                    SetAfficherImage(true);
                  }}
                >
                  <VisibilityIcon fontSize="large" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  sx={{ color: "#E9535D" }}
                  onClick={() => {
                    setTypeDelete(photoType);
                    setDeletePopup(true);
                  }}
                >
                  <CancelIcon fontSize="large" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Grid
        container
        sx={{
          width: {
            md: "100%",
          },
        }}
        marginTop="4%"
        spacing={4}
        textAlign="center"
      >
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              height: "50px",
              backgroundColor:
                selectedOption === "delivery" ? "#e8555f" : "#002244",
              "&:hover": {
                backgroundColor:
                  selectedOption === "delivery" ? "#e8555f" : "#002244", // hover state
              },
            }}
            color="secondary"
            onClick={() => handleSelect("delivery")}
            startIcon={
              <Checkbox
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff", // White color when checked
                  },
                }}
                checked={selectedOption === "delivery"}
              />
            }
          >
            Livraison
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "10px",
              height: "50px",
              backgroundColor:
                selectedOption === "inStore" ? "#e8555f" : "#002244",
              "&:hover": {
                backgroundColor:
                  selectedOption === "inStore" ? "#e8555f" : "#002244", // hover state
              },
            }}
            color="secondary"
            onClick={() => handleSelect("inStore")}
            startIcon={
              <Checkbox
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff", // White color when checked
                  },
                }}
                checked={selectedOption === "inStore"}
              />
            }
          >
            Retrait en magasin
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Link to={`/commande/${selectedOption}`}>
            <ImportButton sx={{ width: "80%", height: "100%" }}>
              confirmer
            </ImportButton>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

function PanierTele({
  photoTypes,
  photos,
  handleSelect,
  selectedOption,
  setSelectedOption,

  setImageType,
  SetAfficherImage,
  setTypeDelete,
  setDeletePopup,
}) {
  console.log(photoTypes);
  const dispatch = useDispatch();
  const styles = {
    paper: {
      margin: "auto",
      padding: "20px",
      width: "100%",
      marginBottom: "60px",
    },
    listItem: {
      width: "100%",
      justifyContent: "space-between",
    },
    button: {
      marginTop: "20px",
      backgroundColor: "#FF1744", // Use the color from your brand palette
      color: "white",
      width: "100%",
      padding: "10px 0",
      textTransform: "none", // If you want to keep the button text as is
    },
    header: {
      marginBottom: "50px",
      fontSize: "25px",
      fontWeight: "bold",
      textAlign: "centre",
    },
  };
  return (
    <Box>
      <Typography style={styles.header}>Ma Commande</Typography>
      <Grid
        container
        spacing={4}
        textAlign="center"
        sx={{ marginBottom: "50px" }}
      >
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            sx={{
              height: "50px",
              borderRadius: "10px",
              backgroundColor:
                selectedOption === "delivery" ? "#e8555f" : "#002244",
              "&:hover": {
                backgroundColor:
                  selectedOption === "delivery" ? "#e8555f" : "#002244", // hover state
              },
              fontSize: {
                xs: "12px",
              },
            }}
            color="secondary"
            onClick={() => handleSelect("delivery")}
            startIcon={
              <Checkbox
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff", // White color when checked
                  },
                }}
                checked={selectedOption === "delivery"}
              />
            }
          >
            Livraison
          </Button>
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            sx={{
              height: "50px",
              borderRadius: "10px",
              backgroundColor:
                selectedOption === "inStore" ? "#e8555f" : "#002244",
              "&:hover": {
                backgroundColor:
                  selectedOption === "inStore" ? "#e8555f" : "#002244", // hover state
              },
              fontSize: {
                xs: "12px",
              },
            }}
            color="secondary"
            onClick={() => handleSelect("inStore")}
            startIcon={
              <Checkbox
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff", // White color when checked
                  },
                }}
                checked={selectedOption === "inStore"}
              />
            }
          >
            Retrait en magasin
          </Button>
        </Grid>
      </Grid>
      <Paper style={styles.paper} elevation={3}>
        <Typography variant="p" padding="10px" color="gray">
          Liste et prix de votre commande
        </Typography>
        <List component="nav">
          {photoTypes.map((photoType) => (
            <ListItem style={styles.listItem}>
              <ListItemText
                primary={`Photo ${
                  photoType.charAt(0).toUpperCase() + photoType.slice(1)
                }`}
              />
              <Typography variant="body2">
                {" "}
                <Badge badgeContent={photos[photoType].length} color="error">
                  <CollectionsIcon />
                </Badge>
                <IconButton
                  onClick={() => {
                    setImageType(photoType);
                    SetAfficherImage(true);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  sx={{ color: "#E9535D" }}
                  onClick={() => {
                    setTypeDelete(photoType);
                    setDeletePopup(true);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Typography>
            </ListItem>
          ))}
        </List>
      </Paper>
      <Link to={`/commande/${selectedOption}`}>
        <ImportButton
          sx={{ width: "100%", minHeight: "60px" }}
        >
          confirmer
        </ImportButton>
      </Link>
    </Box>
  );
}

export default Panier;
