import { Grid, Box, Typography, Button, Badge } from "@mui/material";

import { useMonContexte } from "../../store/ContextComposant";
import BackLoading, {
  BackLoadingSimple,
} from "../../composant/loading/backLoading";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { addImageAndSave } from "../../store/slice/gestionImageSlice";
import { useNavigate } from "react-router";
import ImageCard from "../../composant/ImageGallrey";
import ImageTirage from "../../image/imageTirage.png";
import PhotoImportCard from "../../composant/UploadFiles";
import { ImportButton } from "../../composant/UploadFiles";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useParams } from "react-router";

export default function ImageUploadInstax() {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);
  const { type } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function convertFileToBase64(
    file,
    callback,
    maxWidth = 800,
    maxHeight = 600,
    quality = 0.7
  ) {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            callback(reader.result);
          };
          reader.onerror = (error) => {
            console.error("Error converting file to Base64:", error);
          };
        },
        "image/jpeg",
        quality
      ); // Adjust the format ('image/jpeg') and quality (0.7) as needed
    };
    img.onerror = (error) => {
      console.error("Error loading image:", error);
    };
  }

  async function AddImage(e) {
    const acceptedFiles = e.target.files;
    setLoading(true);
    const imageUploadPromises = Array.from(acceptedFiles).map(
      (file) =>
        new Promise((resolve, reject) => {
          convertFileToBase64(file, (base64String) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
              let cropWidth, cropHeight, startX, startY;

              if (img.width / img.height > 3 / 4) {
                // Landscape
                cropHeight = img.height;
                cropWidth = img.height * (3 / 4);
                startX = (img.width - cropWidth) / 2;
                startY = 0;
              } else {
                // Portrait
                cropWidth = img.width;
                cropHeight = img.width * (4 / 3);
                startX = 0;
                startY = (img.height - cropHeight) / 2;
              }

              // Prepare the canvas for cropping and resizing
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              canvas.width = 713; // Desired final width
              canvas.height = 911; // Desired final height

              // Draw the cropped image on the canvas
              ctx.drawImage(
                img,
                startX,
                startY,
                cropWidth,
                cropHeight,
                0,
                0,
                canvas.width,
                canvas.height
              );

              // Convert canvas to image data
              const resizedImageData = canvas.toDataURL("image/jpeg", 0.9);

              // Resolve with both the original in Base64 and the processed image
              resolve({
                originalBase64: base64String,
                src: resizedImageData,
                marginTop: 51,
                marginLeft: 31,
                height: 1535,
                width: 1063,
                type: type,
              });
            };

            img.onerror = () => reject(new Error("Image loading error"));
          });
        })
    );

    try {
      const processedImages = await Promise.all(imageUploadPromises);
      console.log(processedImages);
      setImage((image) => [...image, ...processedImages]); // Update your state with new images
    } catch (error) {
      console.error("Error processing images:", error);
    } finally {
      setLoading(false); // Make sure to turn off loading state
    }
  }

  const addpanier = () => {
    dispatch(addImageAndSave({ images: image, type: type }));
    navigate("/panier");
  };

  const deleteImage = async (index) => {
    setImage(() => image.filter((item, i) => i !== index));
  };
  console.log(loading);
  return (
    <Box>
      <Grid
        container
        spacing={4}
        sx={{
          marginTop: "8%",
          marginBottom: {
            xs: "15%", // Applies to xs and up, until overridden
            md: "5%", // Overrides the above value from md breakpoint and up
          },
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontWeight: "regular" }}
            >
              Tirage photo
            </Typography>
            <Box
              component="span"
              sx={{
                // margin left and right for the divider
                display: "inline-block",
                height: "24px",
                width: "1px",
                // use your theme's text primary color
              }}
            />
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
              {" "}
              - Tirage {type}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} textAlign="center">
            {image.length == 0 && (
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={ImageTirage}
                  alt="ImageTirage"
                  width="80%"
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={image.length == 0 ? 6 : 12}
              textAlign="center"
            >
              <PhotoImportCard
                AddImage={AddImage}
                affichage={image.length == 0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Grid container spacing={4} textAlign="center">
            {image?.map((image, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                key={index}
                sx={{ width: "100%" }}
                textAlign="center"
              >
                <ImageCard
                  src={image.src}
                  onDelete={() => deleteImage(index)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>{" "}
      {image.length != 0 && (
        <>
          <footer
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid #ccc",
              position: "fixed",
              left: "0",
              bottom: "0",
              width: "100%",
              background: "white",
            }}
          >
            <ImportButton
              variant="contained"
              startIcon={
                <Badge
                  badgeContent={image.length}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#e63e57",
                      backgroundColor: "white",
                    },
                  }}
                >
                  <AddShoppingCartIcon />
                </Badge>
              }
              onClick={addpanier}
            >
              Ajouter au Panier
            </ImportButton>
          </footer>
        </>
      )}
      <BackLoadingSimple open={loading} />
    </Box>
  );
}
