import { useMonContexte } from "../../store/ContextComposant";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Button, Typography, Container } from "@mui/material";
import { useState } from "react";
import BackLoading from "../../composant/loading/backLoading";
import axios from "axios";
import { api_base } from "../../env";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate, useParams } from "react-router";
import ImageCard from "../../composant/ImageGallrey";
import { ImportButton } from "../../composant/UploadFiles";

const ImageApresCommand = () => {
  const { TableauImage, setTableauImage } = useMonContexte();
  const Navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [NumberUpload, setNumberUpload] = useState(0);
  const [NumberFileUpload, setNumberfileUpload] = useState(0);
  const { id } = useParams();
  const [message, setMessage] = useState("image upload en succes");

  const deleteImage = async (index) => {
    console.log("supprimer");
    setTableauImage(() => TableauImage.filter((item, i) => i !== index));
  };

  async function sendData() {
    setNumberUpload(TableauImage.length);
    setLoading(true);
    const dataNotUpload = [];
    for (let index = 0; index < TableauImage.length; index++) {
      const data = { ...TableauImage[index], idcommand: id };
      try {
        await axios.post(`${api_base}nodeCamera/addManyImage`, data);
        setNumberfileUpload(index + 1);
      } catch (error) {
        dataNotUpload.push(TableauImage[index]);
        continue;
      }
    }
    setLoading(false);
    if (!dataNotUpload.length) {
      alert("image upload succes");
      Navigate("/");
    } else {
      setTableauImage(dataNotUpload);
    }
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: 0,
        marginTop: "50px",
        "@media (max-width: 600px)": {
          padding: "10px",
        },
      }}
    >
      <Grid container>
        {TableauImage && TableauImage.length > 0 ? (
          TableauImage.map((image, index) => (
            <Grid item xs={12} sm={6} md={2} key={index}>
              <ImageCard
                src={image.src}
                onDelete={() => {
                  deleteImage(index);
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} textAlign="center" justifyContent="center">
            <Typography variant="h3">{message}</Typography>
          </Grid>
        )}
        {TableauImage && TableauImage.length > 0 ? (
          <Grid item xs={12} textAlign="center">
            <ImportButton onClick={sendData} endIcon={<SendIcon />}>
              Send Image
            </ImportButton>
          </Grid>
        ) : null}
        <BackLoading
          open={Loading}
          numberfile={NumberUpload}
          fileupload={NumberFileUpload}
        />
      </Grid>
    </Container>
  );
};

export default ImageApresCommand;
